import { FC, PropsWithChildren, useMemo } from "react"
import { Provider, Client, createClient } from "urql"

const UrqlProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const client: Client = useMemo(() => {
    return createClient({ url: "/api/v1/graphql" })
  }, [])
  return <Provider value={client}>{children}</Provider>
}

export { UrqlProvider }
